<template>
    <el-container>
        <el-header height="54px" style="padding:0 10px">
            <el-row>
                <el-col :span="21">
                    <el-form :inline="true" :model="formData" style="margin-top: 10px;">
                        <el-form-item label="货号：">
                            <el-input v-model="formData.itemNo" @change="handleSearch" clearable
                                placeholder="请输入货号"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button type="primary" icon="el-icon-plus" @click="handleAddNew">新增</el-button>
                            <el-button type="success" plain icon="el-icon-upload2" @click="handleImport">导入</el-button>
                            <el-button type="primary" plain icon="el-icon-download" :loading="loading"
                                @click="handleExport">导出</el-button>
                            <el-button type="warning" plain icon="el-icon-connection" @click="handleSynPlatform">同步设置</el-button>
                            <el-button type="danger" plain icon="el-icon-setting" @click="handleSynAccount">账号设置</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="3">
                    <el-tabs v-model="formData.isOnSale" @tab-click="handleSearch" stretch>
                        <el-tab-pane label="在售" name="0"></el-tab-pane>
                        <el-tab-pane label="下架" name="1"></el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </el-header>
        <el-main>
            <el-table stripe border ref="productTable" row-key="ProductID" :data="tableData.items"
                v-loading="tableData.loading" :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                height="100%">
                <el-table-column type="selection" width="39" />
                <el-table-column prop="ItemNo" label="货号" width="200" fixed>
                    <template slot-scope="{ row }">
                        {{ row.ItemNo }}
                        <span class="el-icon-edit-outline" v-if="row.ItemNo != ''" style="cursor: pointer;"
                            @click="handleDialogShow(row, 'item')" />
                    </template>
                </el-table-column>
                <el-table-column prop="Colors" label="颜色">
                    <template slot-scope="{ row }">
                        {{ row.Colors }}
                        <span class="el-icon-edit-outline" v-if="row.Colors != ''" style="cursor: pointer;"
                            @click="handleDialogShow(row, 'color')" />
                    </template>
                </el-table-column>
                <el-table-column prop="Sizes" label="尺码" />
                <el-table-column prop="DefaultSupplierName" label="供应商" />
                <el-table-column prop="Price" label="商品价">
                    <template slot-scope="{ row }">
                        {{ row.Price }}
                        <span class="el-icon-edit-outline" style="cursor: pointer;"
                            @click="handleDialogShow(row, 'item')" />
                    </template>
                </el-table-column>
                <el-table-column prop="Cost" label="采购价">
                    <template slot-scope="{ row }">
                        {{ row.Cost }}
                        <span class="el-icon-edit-outline" style="cursor: pointer;"
                            @click="handleDialogShow(row, 'item')" />
                    </template>
                </el-table-column>
                <el-table-column prop="Memo" label="备注" />
                <el-table-column label="状态" width="80" align="center" header-align="center">
                    <template slot-scope="scope">{{ scope.row.IsOnSale == 0 ? '在售' : '下架' }}</template>
                </el-table-column>
                <el-table-column label="操作" width="215" align="center" header-align="center">
                    <template slot-scope="scope">
                        <el-button plain size="mini" @click="handleEdit(scope.row)"
                            v-if="scope.row.IsOnSale == 0">编辑</el-button>
                        <el-button plain size="mini" type="primary" @click="handleState(scope.row)">{{
                            scope.row.IsOnSale == 0 ? '下架' : '上架' }}</el-button>
                        <el-button plain size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer height="45px">
            <el-pagination background :total="tableData.total" :page-size="tableData.pageSize"
                :current-page="tableData.currentPage" :page-sizes="[10, 15, 20, 30, 50]"
                layout="prev, pager, next,total, sizes" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </el-footer>
        <product-import ref="ProductImport" @handleSearch="handleSearch" />
        <product-edit-simple ref="productEditSimple" @handleSearch="handleSearch" />
        <product-setting ref="productSettingDlg" @handleSearch="handleSearch" />
        <syn-platform-setting ref="synPlatformSettingDlg" />
        <syn-account-setting ref="synAccountSettingDlg" />
        <helper-center ref="helperCenter" position="product" :visible="true"></helper-center>
    </el-container>
</template>
<script>
import { submit, exportFile } from "@/api/base";
import ProductImport from './components/Import.vue';
import ProductEditSimple from "./components/Edit.vue";
import ProductSetting from "./components/ProductSetting.vue";
import SynPlatformSetting from "./components/SynPlatformSetting.vue";
import SynAccountSetting from "./components/SynAccountSetting.vue";
import HelperCenter from '@/views/stall/trade/components/HelperCenter.vue';

export default {
    name: 'productSimple',
    components: {
        ProductImport,
        ProductEditSimple,
        ProductSetting,
        SynPlatformSetting,
        SynAccountSetting,
        HelperCenter
    },
    data() {
        return {
            loading: false,
            formData: {
                itemNo: '',
                isOnSale: 0
            },
            tableData: {
                loading: false,
                items: [],
                total: 0,
                pageSize: 15,
                currentPage: 1
            },
        }
    },
    mounted() {
        const _this = this;
        _this.$nextTick(async () => {
            await _this.fetchData();
        });
    },
    methods: {
        async fetchData() {
            const _this = this;

            _this.tableData.loading = true;
            const { data } = await submit("/api/product/getList", {
                PageSize: _this.tableData.pageSize,
                CurrentPage: _this.tableData.currentPage,
                ItemNo: _this.formData.itemNo,
                IsOnSale: _this.formData.isOnSale,
                IncludeColorsAndSizes: true
            }).finally(() => {
                _this.tableData.loading = false;
            });

            _this.tableData.total = data.total;
            _this.tableData.items = data.datas;
        },
        handleSearch() {
            this.fetchData();
        },
        handleAddNew() {
            let postData = {
                ItemNo: '',
                ProductID: 0,
                IncludeSettle: false,
                Type: 'item'
            };
            this.$refs.productSettingDlg.open(postData);
        },
        handleEdit(row) {
            let postData = {
                ItemNo: row.ItemNo,
                ProductID: row.ProductID,
                IncludeSettle: false,
                Type: 'item'
            };
            this.$refs.productSettingDlg.open(postData);
        },
        //页面弹窗操作
        handleDialogShow(row, type) {
            let postData = {
                ItemNo: row.ItemNo,
                ProductID: row.ProductID,
                IncludeSettle: false,
                Type: type
            };
            this.$refs.productSettingDlg.open(postData);
        },
        handleState(row) {
            const action = row.IsOnSale === 0 ? '下架' : '上架';
            this.$confirm(`您是否要${action}货品：${row.ItemNo} ?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const result = await submit('/api/product/changeOnSale', {
                    ProductIDs: row.ProductID,
                    IsOnSale: row.IsOnSale === 0 ? 1 : 0
                });
                if (result.success) {
                    this.$message({
                        type: 'success',
                        message: `${action}成功!`
                    });
                    this.fetchData();
                }

            });
        },
        handleDelete(row) {
            this.$confirm(`您是否要删除货品：${row.ItemNo} ?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const result = await submit('/api/product/Delete', {
                    ProductIDs: row.ProductID
                });
                if (result.success) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.fetchData();
                }

            });
        },
        handleSynAccount() {
            this.$refs.synAccountSettingDlg.open();
        },
        handleSynPlatform() {
            this.$refs.synPlatformSettingDlg.open();
        },
        handleImport() {
            const _this = this;
            _this.$nextTick(() => {
                _this.$refs.ProductImport.initDialog();
            });
        },
        async handleExport() {
            const selection = JSON.parse(JSON.stringify(this.$refs.productTable.selection));

            this.loading = true;
            const { data } = await exportFile('/api/product/exportEx', {
                ProductIDs: selection.map(p => { return p.ProductID }).join(','),
                IsOnSale: this.formData.isOnSale
            }).finally(() => { this.loading = false; });

            const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = data.FileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        handleSizeChange(size) {
            this.tableData.pageSize = size;
            this.fetchData();
        },
        handleCurrentChange(page) {
            this.tableData.currentPage = page;
            this.fetchData();
        }
    }
}
</script>